import React from "react";
import { Brands } from "./components/Brands";
import { Services } from "./components/Services";
import { ToWatch } from "./components/ToWatch";
import { SearchBar } from "./components/SearchBar";
import { Brand } from "./api/BrandApi";
import { Pricing } from "./api/AuthApi";
import { SearchOptions } from "./api/TyreApi";
import { Helmet } from "react-helmet";
import ogImage from "./img/searchImage.jpeg";
import { getAlternateCanonical } from "./AllSeasonTyres";

export function Home({
  brands,
  pricing,
  options,
}: {
  brands: Brand[];
  pricing: Pricing;
  options: SearchOptions;
}) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {getAlternateCanonical()}
        <title>Autobanden online kopen in Nederland bij Banden-Koning.nl</title>
        <meta
          name="title"
          content="Autobanden online kopen in Nederland bij Banden-Koning.nl"
        />
        <meta
          name="description"
          content="Banden online bestellen in Nederland doe je het goedkoopst en snelst bij Banden-Koning.nl. Geniet van GRATIS levering bij één van onze vele partners."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://banden-koning.nl/" />
        <meta
          property="og:title"
          content="Autobanden online kopen in Nederland bij Banden-Koning.nl"
        />
        <meta
          property="og:description"
          content="Banden online bestellen in Nederland doe je het goedkoopst en snelst bij Banden-Koning.nl. Geniet van GRATIS levering bij één van onze vele partners. Vind jouw bandenmaat in ons ruim assortiment."
        />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <SearchBar brands={brands} options={options} />
      <ToWatch brands={brands} pricing={pricing} />
      <Brands brands={brands} />
      <Services />
    </>
  );
}
