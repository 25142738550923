import React from "react";
import { TermsNl } from "./components/TermsNl";
import { Helmet } from "react-helmet";

export function Terms() {
  return (
    <div className="containerTransparant">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Algemene voorwaarden | Banden-Koning.nl
        </title>
      </Helmet>
      <TermsNl />
    </div>
  );
}
