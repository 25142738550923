import React, { useState } from "react";
import { AdminHeader } from "./AdminHeader";
import {
  getPersonalInformation,
  PersonalInformation,
  updatePersonalInformation,
} from "./api/AuthApi";
import { Select } from "./Select";
import { useEffectOnce } from "react-use";
import { syncAllProds, syncAllStocks } from "./api/OrderApi";
import { Helmet } from "react-helmet";
import { DateTime, Duration } from "luxon";
import { getLatestSync } from "./api/TyreApi";

export function AdminSettings() {
  const [infoNl, setInfoNl] = useState<PersonalInformation>();
  const [settingsSavedMessage, setSettingsSavedMessage] = useState<null | {
    success: boolean;
    message: string;
  }>(null);
  const [syncing, setSyncing] = useState(false);
  const [message, setMessage] = useState("");
  const [stocksTimer, setStocksTimer] = useState(0);
  const [allTimer, setAllTimer] = useState(0);
  const [latest, setLatest] = useState<DateTime | null>(null);

  useEffectOnce(() => {
    getPersonalInformation(true).then(result => {
      setInfoNl(result);
    });
    getLatestSync().then(r => {
      setLatest(r);
    });
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Site instellingen | Admin</title>
      </Helmet>
      <AdminHeader />
      <div className="admin-dashboard">
        <div>
          <h2>Nederland</h2>
          <div className="w-45">
            <form
              onSubmit={async e => {
                e.preventDefault();

                if (infoNl) {
                  try {
                    await updatePersonalInformation(
                      infoNl.priceType,
                      infoNl.vat,
                      infoNl.transportCost,
                      infoNl.recupelC1,
                      infoNl.recupelC2,
                      infoNl.onePieceExtra,
                      true
                    );
                    setSettingsSavedMessage({
                      success: true,
                      message: "Gegevens opgeslagen!",
                    });
                  } catch (e) {
                    setSettingsSavedMessage({
                      success: false,
                      message: "Er is iets fout gelopen",
                    });
                  }

                  setTimeout(() => {
                    setSettingsSavedMessage(null);
                  }, 1000 * 3);
                }
              }}
            >
              <h1>Settings</h1>
              <Select
                search={false}
                title={"Prijs type"}
                tooltip={false}
                options={[
                  { label: "BC", value: "BC" },
                  { label: "Garage", value: "Garage" },
                  { label: "Garage12", value: "Garage12" },
                ]}
                selected={infoNl?.priceType || ""}
                selectOption={selected => {
                  if (infoNl) {
                    setInfoNl({
                      ...infoNl,
                      priceType: selected,
                    });
                  }
                }}
              />
              <label>Transportkosten</label>
              <input
                className="input"
                type="number"
                placeholder="Transportkosten"
                required={true}
                value={infoNl?.transportCost}
                step={0.01}
                onChange={e => {
                  if (infoNl) {
                    setInfoNl({
                      ...infoNl,
                      transportCost: parseFloat(e.target.value),
                    });
                  }
                }}
              />
              <label>Transportkosten toeslag 1 stuk</label>
              <input
                className="input"
                type="number"
                placeholder="Transportkosten toeslag 1 stuk"
                required={true}
                value={infoNl?.onePieceExtra}
                step={0.01}
                onChange={e => {
                  if (infoNl) {
                    setInfoNl({
                      ...infoNl,
                      onePieceExtra: parseFloat(e.target.value),
                    });
                  }
                }}
              />
              <label>Recupel C1</label>
              <input
                className="input"
                type="number"
                placeholder="Recupel C1"
                required={true}
                value={infoNl?.recupelC1}
                step={0.01}
                onChange={e => {
                  if (infoNl) {
                    setInfoNl({
                      ...infoNl,
                      recupelC1: parseFloat(e.target.value),
                    });
                  }
                }}
              />
              <label>Recupel C2</label>
              <input
                className="input"
                type="number"
                placeholder="Recupel C2"
                required={true}
                value={infoNl?.recupelC2}
                step={0.01}
                onChange={e => {
                  if (infoNl) {
                    setInfoNl({
                      ...infoNl,
                      recupelC2: parseFloat(e.target.value),
                    });
                  }
                }}
              />
              <label>BTW</label>
              <input
                className="input"
                type="number"
                placeholder="BTW"
                required={true}
                value={infoNl?.vat}
                step={0.01}
                onChange={e => {
                  if (infoNl) {
                    setInfoNl({
                      ...infoNl,
                      vat: e.target.value,
                    });
                  }
                }}
              />
              {settingsSavedMessage ? (
                <div className={`${settingsSavedMessage.success ? "" : "red"}`}>
                  {settingsSavedMessage.message}
                </div>
              ) : null}
              <button className="button mt-4" type="submit">
                Opslaan
              </button>
            </form>
          </div>
        </div>
        <div>
          <button
            className="button w-fit mt-4"
            disabled={syncing}
            onClick={async () => {
              if (syncing) {
                return;
              }

              setSyncing(true);
              const i = setInterval(() => {
                setAllTimer(a => a + 1000);
              }, 1000);
              try {
                const result = await syncAllProds();
                if (result) {
                  setMessage("Alle producten syncen is voltooid");
                } else {
                  setMessage("Kon niet producten syncen");
                }
              } catch (e) {
                setMessage(`Kon niet producten syncen: ${e}`);
              }

              setSyncing(false);
              clearInterval(i);
            }}
          >
            {syncing ? <b>Synchronizing</b> : "Synchronize"} all data product
            from CRM{" "}
            {syncing
              ? `-> ${Duration.fromMillis(allTimer).toFormat("mm:ss")}`
              : null}
          </button>
          <button
            className="button w-fit mt-4"
            disabled={syncing}
            onClick={async () => {
              if (syncing) {
                return;
              }

              setSyncing(true);
              const i = setInterval(() => {
                setStocksTimer(a => a + 1000);
              }, 1000);
              try {
                const result = await syncAllStocks();

                if (result) {
                  setMessage("Alle stocks syncen is voltooid");
                } else {
                  setMessage(`Kon niet alle stocks syncen`);
                }
              } catch (e) {
                setMessage(`Kon niet alle stocks syncen: ${e}`);
              }

              clearInterval(i);
              setSyncing(false);
            }}
          >
            {syncing ? <b>Synchronizing</b> : "Synchronize"} all stock & prizes
            from CRM{" "}
            {syncing
              ? `-> ${Duration.fromMillis(stocksTimer).toFormat("mm:ss")}`
              : null}
          </button>
          {latest ? (
            <div>
              Laatste succesvolle sync: {latest.toFormat("dd/LL/yyyy HH:mm")}
            </div>
          ) : null}
        </div>
        {message}
      </div>
    </>
  );
}
