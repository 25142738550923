import React, { useEffect, useState } from "react";
import bancontact from "../img/paymethods/bancontact.svg";
import mastercard from "../img/paymethods/mc_symbol.svg";
import kbc from "../img/paymethods/kbc.png";
import belfius from "../img/paymethods/belfius.svg";
import american from "../img/paymethods/American_Express.svg";
import paypal from "../img/paymethods/paypal.svg";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { Brand, getBrands } from "../api/BrandApi";
import { useEffectOnce } from "react-use";
import { capitalize, compareBrands, isFR, isNL } from "../Utils";
import { subscribe } from "../api/AuthApi";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
  listMethodsByCountry,
  PaymentMethod as PaymentMethodType,
} from "../api/PaymentApi";
import { availableMethods } from "../AdminMethods";

export function Footer({ brands }: { brands: Brand[] }) {
  const { t } = useTranslation("footer");

  const [brand, setBrand] = useState<Brand[]>([]);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [availableMethodsForUser, setAvailableMethods] = useState<
    PaymentMethodType[]
  >([]);

  useEffectOnce(() => {
    getBrands().then(result => {
      setBrand(result.filter(filter => filter.categoryId === "1").slice(0, 7));
    });

    listMethodsByCountry("nl").then(result => {
      setAvailableMethods(result);
    });
  });

  brand.sort(compareBrands);

  const socialMedia = [
    {
      name: "Facebook",
      icon: <FaFacebookF />,
      url: "https://www.facebook.com/BandenKoning.be/",
    },
    {
      name: "Instagram",
      icon: <FaInstagram />,
      url: "https://www.instagram.com/BandenKoning.be/",
    },
    /*{
      name: "Twitter",
      icon: <FaTwitter />,
      url: "https://twitter.com/",
    },
    {
      name: "LinkedIn",
      icon: <FaLinkedinIn />,
      url: "https://nl.linkedin.com/",
    },*/
  ];
  const customerService = [
    {
      title: t("customer_service.contact"),
      url: "/contact",
    },
    {
      title: t("customer_service.faq"),
      url: "/faq",
    },
    {
      title: t("customer_service.return"),
      url: "/return-policy",
    },
    {
      title: t("customer_service.terms"),
      url: "/terms",
    },
    {
      title: "Blog",
      url: "/blogs",
    },
  ];
  const tyres = [
    {
      title: t("tyres.summer"),
      url: "/tyres/summer",
    },
    {
      title: t("tyres.winter"),
      url: "/tyres/winter",
    },
    {
      title: t("tyres.all_season"),
      url: "/tyres/allseason",
    },
    {
      title: t("tyres.car"),
      url: "/tyres/car",
    },
    {
      title: t("tyres.van"),
      url: "/tyres/van",
    },
    {
      title: t("tyres.jeep"),
      url: "tyres/4x4",
    },
  ];
  const montage = [
    {
      title: t("montage.options"),
      url: "/delivery-options",
    },
    {
      title: t("montage.partners"),
      url: "/partners",
    },
    {
      title: t("montage.become_partner"),
      url: "/partner-login",
    },
  ];

  return (
    <>
      <div className="greenFooter footer">
        <div className="blackRow">
          <div className="blackColumn">
            <div className="title">{t("how_help")}</div>
            <p>{t("mail_to")}</p>
            <a href="mailto:info@banden-koning.nl" id="contactMailFooter">
              info@banden-koning.nl
            </a>
          </div>
          <div className="blackColumn">
            <div className="title">{t("follow_us")}</div>
            <div className="flex">
              {socialMedia.map(icon => (
                <a href={icon.url}
                   target="_blank"
                   className="social"
                   id={"FooterIcon" + icon.name}
                >
                  {icon.icon}
                </a>
              ))}
            </div>
          </div>
          <div className="blackColumn">
            <div className="title">{t("promotions.title")}</div>
            <p>{t("promotions.subtitle1")}</p>
            <p>{t("promotions.subtitle2")}</p>
            <div className="flex items-center wrap">
              <form
                id="subscribeToNewsletter"
                onSubmit={async e => {
                  e.preventDefault();

                  await subscribe(email);
                  setEmail("");
                  setSuccess(true);
                }}
              >
                <input
                  className="mw-100"
                  placeholder={t("fillIn")}
                  size={40}
                  type={"email"}
                  required={true}
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                />
                {success ? (
                  <div className="green">
                    U bent succesvol ingeschreven op onze nieuwsbrief!
                  </div>
                ) : null}
                <button className="subscribe" type={"submit"} id="newsletterSubscription">
                  {t("promotions.subscribe")}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="whiteRow">
          <div className="whiteColumn">
            <div className="title">{t("customer_service.title")}</div>
            {customerService.map((service, i) => (
              <Link to={service.url} key={i}>
                {service.title}
              </Link>
            ))}
          </div>
          <div className="whiteColumn">
            <div className="title">{t("tyres.title")}</div>
            {tyres.map((tyre, i) => (
              <Link to={tyre.url} key={i}>
                {tyre.title}
              </Link>
            ))}
          </div>
          <div className="whiteColumn">
            <div className="title">{t("brands")}</div>
            {brand.map((brand, i) => (
              <Link
                to={
                  (isFR() ? "/marques-pneus/" : "/bandenmerken/") +
                  brand.name
                    .toLowerCase()
                    .trim()
                    .replaceAll(" ", "-") +
                  "-" +
                  brand.id
                }
                title={capitalize(brand.name)}
                key={i}
              >
                {capitalize(brand.name)}
              </Link>
            ))}
          </div>
          <div className="whiteColumn">
            <div className="title">{t("montage.title")}</div>
            {montage.map((option, i) => (
              <Link to={option.url} key={i}>
                {option.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-bottom flex items-center justify-center">
        <div className="footerColumn">
          <p>&#169; 2023 Banden-Koning.nl</p>
        </div>

        <div className="payments-container">
          <div className="payments flex">
            {availableMethodsForUser.map(item => {
                const foundOption = availableMethods.find(
                  method => method.value === item.name
                );

                if(!foundOption){
                  return null;
                }

                return (
                  <img className="icon" src={foundOption.img} alt={foundOption.label} />
                );
              })
            }
          </div>
        </div>

        <div className="footerColumn rightAlign">
          {process.env.REACT_APP_VERSION_NUMBER !== undefined ? "V." + process.env.REACT_APP_VERSION_NUMBER : ""}
        </div>
      </div>
    </>
  );
}
