import React from "react";
import { FaqNl } from "./components/FaqNl";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export function Faq() {
  return (
    <div className="containerTransparant">
      <Helmet>
        <title>Veel gestelde vragen | Banden-Koning.nl</title>
        <meta name="title" content="Veel gestelde vragen | Banden-Koning.nl" />
        <meta name="description" content="Autobanden goedkoop online en snel geleverd? Lees hoe dit werkt bij Banden-Koning.nl"/>
        </Helmet>
        <FaqNl />
    </div>
  );
}
