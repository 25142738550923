import React, { useState } from "react";
import "./Brands.css";
import { Brand, getBrands } from "../api/BrandApi";
import { useEffectOnce } from "react-use";
import { capitalize, isFR } from "../Utils";
import { Link } from "react-router-dom";

export function Brands({ brands }: { brands: Brand[] }) {

  const [brand, setBrand] = useState<Brand[]>([]);

  useEffectOnce(() => {
    getBrands().then(result => {
      setBrand(result.filter(filter => filter.categoryId !== null));
    });
  });

  const columns = brand.slice(0, 10).map((brand, index) => (
    <div className="flex flex-column">
      <div key={index} className="brandItem">
        <Link
          to={
            (isFR() ? "/marques-pneus/" : "/bandenmerken/") +
            brand.name
              .toLowerCase()
              .trim()
              .replaceAll(" ", "-") +
            "-" +
            brand.id
          }
          title={capitalize(brand.name)}
        >
          <img src={brand.logo} alt={capitalize(brand.name)} />
        </Link>
      </div>
      <h4 style={{ marginBottom: "10px", marginTop: "-12px" }}>
        {capitalize(brand.name)}
      </h4>
      <Link
        to={
          (isFR() ? "/marques-pneus/" : "/bandenmerken/") +
          brand.name
            .toLowerCase()
            .trim()
            .replaceAll(" ", "-") +
          "-" +
          brand.id
        }
        title={capitalize(brand.name)}
        className="button w-fit mb-4"
      >
        Bekijk merk
      </Link>
    </div>
  ));
  return (
    <div className="containerLightGreen">
      <h2 className="mt-auto">Ontdek ons aanbod van meer dan 40 merken!</h2>
      <p className="brandDescription">
        Bij Banden-Koning.nl kan je niet alleen terecht voor een uitstekende service,
        maar ook staan wij garant voor een uitgebreid aanbod. Met dank aan ons groot
        netwerk binnen Europa kunnen wij alle merken aanbieden tegen competitieve prijzen.
        Voor zowel premium bandenmerken, als ecologische bandenmerken kan je steeds op onze site terecht.
      </p>
      <div className="flex wrap">{columns}</div>
      <button className="brandButton">
        <Link to="/brands">Bekijk meer</Link>
      </button>
    </div>
  );
}
