import React from "react";
import { SearchBarSmall } from "./components/SearchBarSmall";
import { Brand } from "./api/BrandApi";
import { SearchOptions } from "./api/TyreApi";
import { ContactSheet } from "./components/ContactSheet";
import { Helmet } from "react-helmet";
import { isNL } from "./Utils";

export function Contact({
  brands,
  options,
}: {
  brands: Brand[];
  options: SearchOptions;
}) {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contacteer ons | Banden-Koning.nl</title>
      </Helmet>
      <SearchBarSmall brands={brands} options={options} />
      <h1 className="green w-80 m-auto">Contacteer ons</h1>
      <div className="flex containerTransparant contact">
        <p>
          Het team van Banden-Koning.nl is er om je te helpen in je keuze.
          Heb je vragen over je bestelling, de werking van de site of iets anders?
          Stuur een mail naar <a href='mailto:info@banden-koning.nl' className='link'>info@banden-koning.nl</a> of stel je
          vraag via het <strong>contactformulier</strong>. Wij reageren zo snel mogelijk.
        </p>
        <ContactSheet />
      </div>
    </>
  );
}
