import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { isNL } from "./Utils";
import { getAlternateCanonical } from "./AllSeasonTyres";

export function DeliveryOptions() {
  const { t } = useTranslation("delivery-options");

  return (
    <div className="containerTransparant w-50">
      <Helmet>
        {getAlternateCanonical()}
        <link rel="canonical" href={window.location.href} />
        <title>
          Autobanden online kopen met montage | Banden-Koning.nl
        </title>
        <meta name="description" content="Autobanden montage dichtbij en goedkoop? Weet waarvoor u betaalt en koop uw autobanden voordelig en laat ze eenvoudig monteren en balanceren bij één van onze montagepartners bij u in de buurt." />
        <meta name="keywords" content="Autobanden, montage, online banden, banden online, waar monteren" />
      </Helmet>
      <h1 className="green">Keuze uit twee leveringsvoorkeuren</h1>
      <div>
        <div>
          <h2>Levering bij een van onze montagepartners</h2>
          <p>
            Bij de bestelprocedure kan je deze optie aanduiden. Vervolgens kan je jouw montagepartner in de buurt selecteren.
          </p>
          <p>
            Jouw bestelling wordt door ons rechtstreeks bij de montagepartner geleverd.
            Hierna rest jou enkel nog een afspraak te maken met de montagepartner
            voor het monteren van jouw banden.
          </p>
        </div>
        <div>
          <h2>Levering aan huis</h2>
          <p>
            Staat jouw vaste garagist niet op onze website? Geen nood! Je kan ook zelf instaan voor de montage van jouw
            banden. Deze optie vind je ook bij de bestelprocedure. Duid deze optie aan
            en uw bestelling wordt geleverd op het door u opgegeven adres, bij uw vaste garage bijvoorbeeld.
          </p>
        </div>
      </div>
    </div>
  );
}
