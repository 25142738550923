import React from "react";

export function Returns() {

  return (
    <div className="containerTransparant w-45">
      <h1 className="green">Retourneren</h1>
      <h2>Hoe kan ik gebruik maken van mijn herroepingsrecht?</h2>
      <div>
        <p>Binnen een termijn van 14 kalenderdagen heb je het recht zonder opgave van redenen de overeenkomst te herroepen.
          De herroepingstermijn verstrijkt 14 kalenderdagen na de dag waarop je of een door jou aangewezen derde, die niet
          de vervoerder is, het goed fysiek in bezit krijgt. Om het herroepingsrecht uit te oefenen, moet je ons via een
          ondubbelzinnige verklaring (bijvoorbeeld schriftelijk, per post of e-mail) op de hoogte stellen de overeenkomst
          te herroepen. Je kan hiervoor gebruikmaken van het door ons
          toegezonden <a href="./files/Modelformulier voor herroeping.pdf" target="_blank" className="link" download>modelformulier</a> voor herroeping (PDF), maar je bent hiertoe niet verplicht. Om de herroepingstermijn na te leven volstaat het om je mededeling betreffende uw uitoefening van het herroepingsrecht te verzenden voordat de herroepingstermijn is verstreken. Gevolgen van de herroeping als je de overeenkomst herroept, ontvang je alle betalingen die je tot op dat moment hebt gedaan, inclusief leveringskosten (met uitzondering van eventuele extra kosten ten gevolge van jouw keuze voor een andere wijze van levering dan de door ons geboden goedkoopste standaard levering) onverwijld en in ieder geval niet later dan 14 kalenderdagen nadat wij op de hoogte zijn gesteld van jouw beslissing de overeenkomst te herroepen, van ons terug. Wij betalen je terug met hetzelfde betaalmiddel als waarmee je de oorspronkelijke transactie hebt verricht, tenzij je uitdrukkelijk anderszins hebt ingestemd; in ieder geval zal je voor zulke terugbetaling geen kosten in rekening worden gebracht. Wij mogen wachten met terugbetaling tot wij de goederen hebben teruggekregen, of je hebt aangetoond dat je de goederen hebt teruggezonden, al naar gelang welk tijdstip eerst valt. Je dient de goederen onverwijld, doch in ieder geval niet later dan 14 kalenderdagen na de dag waarop je het besluit de overeenkomst te herroepen aan ons heeft medegedeeld, aan ons terug te zenden of te overhandigen. Je bent op tijd als je de goederen terugstuurt voordat de termijn van 14 kalenderdagen is verstreken. De directe kosten van het terugzenden van de goederen komen voor jouw rekening. Je bent alleen aansprakelijk voor de waardevermindering van de goederen die het gevolg is van het gebruik van de goederen, dat verder gaat dan nodig is om de aard, de kenmerken en de werking van de goederen vast te stellen.</p>
      </div>
    </div>
  );
}
