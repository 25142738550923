import React from "react";
import zomerbanden from "../img/services/zomerbanden.jpeg";
import winterbanden from "../img/services/winterbanden.png";
import fourseasons from "../img/services/4seasons.jpg";
import personenwagens from "../img/services/personenwagen.jpg";
import bestelwagens from "../img/services/van.jpeg";
import suv from "../img/services/suv.jpg";
import "./Services.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function Services() {
  const { t } = useTranslation("home");

  const items = [
    {
      image: zomerbanden,
      description: t("season.summer"),
      link: "/tyres/summer",
    },
    {
      image: winterbanden,
      description: t("season.winter"),
      link: "/tyres/winter",
    },
    {
      image: fourseasons,
      description: t("season.allSeason"),
      link: "/tyres/allseason",
    },
    {
      image: personenwagens,
      description: t("season.passengerCar"),
      link: "/",
    },
    {
      image: bestelwagens,
      description: t("season.van"),
      link: "/",
    },
    {
      image: suv,
      description: t("season.SUV"),
      link: "/",
    },
  ];

  const services = items.map((service, index) => (
    <Link to={service.link} key={index} className="serviceItem">
      <img src={service.image} alt={service.description} />
      <p className="boldGreen">{service.description}</p>
    </Link>
  ));
  return (
    <>
      <div className="containerGreen">
        <h2 className="white">{t("services")}</h2>
        <div
          className="serviceDescription">
          <p>Voor iedere wagen de <strong>geschikte</strong> band die past bij jouw rijprofiel.
            Zomerbanden, winterbanden of liever all-seasonbanden voor personenwagens, bestelwagens
            en SUV’s? <br /> Dat vind je bij <strong>Banden-Koning.nl</strong>
          </p>,
        </div>
        <div className="flex wrap">{services}</div>
      </div>
        <div className="containerTransparant">
          <h1>Banden online kopen doe je best bij Banden-Koning.nl</h1>
          <h2 className="green mt-0">Alles wat je moet weten:</h2>
          <p>
            De juiste banden zorgen mee voor je <strong>veiligheid</strong> op de weg.<br />
            Daarom is het essentieel om je eerst goed te informeren voor je tot een aankoop overgaat.<br />
           <a className="link" href="/tyre-guide">Hier</a> vind je alle informatie die nodig is om de juiste keuze te maken.
          </p>
          <p>
            Bijna 40 merken van <strong>premium merken tot low budget</strong>. <br />
            Alle prijzen zijn <strong>inclusief btw én verzendkosten</strong>.
          </p>
          <p>
            De handige tool bovenaan op de pagina helpt jou bij het uitzoeken van de meest geschikte banden voor je voertuig. <br/>
            Onze handige <a href="/tyre-guide" className="link">bandengids</a> begeleid je om de juiste parameters in te vullen.<br/>
            De <strong>zoekfunctie</strong> kan verfijnd worden naar merk, seizoen, voertuigtype, laad- en snelheidsindex …<br/>
            En heb je toch nog vragen of raak je er niet aan uit?<br/>
            Dan neem je toch even <a href="/contact" className="link">contact</a> met ons op? Wij helpen je met plezier bij je keuze.
          </p>
          <p>
            Bij Banden-Koning.nl is banden online kopen een fluitje van een cent.<br />
            Snel en eenvoudig <strong>bij jou thuis geleverd of bij een van onze montagepartners</strong>.<br />
            Aan jou de keuze.
          </p>
        </div>
    </>
  );
}
