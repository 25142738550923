import React from "react";
import { Link } from "react-router-dom";
import { isNL } from "../Utils";

export function TermsNl() {
  return (
    <>
      <h1 className="green mt-auto">Algemene voorwaarden</h1>
      <h3>Artikel 1. Toepassingsgebied</h3>
      <p>
        Deze algemene voorwaarden zijn van toepassing op iedere overeenkomst
        tussen de klant (<b>Consument</b>) en BEL-IDE HOLDING BV, met
        maatschappelijke zetel te Restert 66A, 9320 Aalst, BTW BE0692.975.423 (
        <b>Verkoper</b>).
        <br />
        Tussen de Consument en de Verkoper ontstaat een overeenkomst (
        <b>Overeenkomst</b>) van zodra de Consument de bestelprocedure via{" "}
        <Link to="/" className="link">
          www.banden-koning.nl
        </Link>{" "}
        (<b>Webshop</b>) heeft doorlopen en een bevestigingsmail heeft
        ontvangen, overeenkomstig artikel 3 van deze algemene voorwaarden.
        <br />
        Deze algemene voorwaarden zullen voorrang hebben op alle andere
        (algemene) voorwaarden die desgevallend van toepassing kunnen zijn op de
        Overeenkomst of tussen partijen.
      </p>
      <h3>Artikel 2. Prijs</h3>
      <p>
        <b>2.1.</b> De prijzen vermeld in de Webshop zijn inclusief BTW,
        exclusief verzendkosten, montage en installatie.
        <br />
        <b>2.2.</b> De verzendkosten worden toegevoegd bij de afrekening van de
        bestelling in het winkelwagenoverzicht en zijn afhankelijk van (i) de
        totaalprijs van de bestelling en (ii) het leveringsadres (overeenkomstig
        artikel 3).
      </p>
      <h3>Artikel 3. Bestelprocedure en betaalmethode</h3>
      <p>
        Bestellingen worden gedaan via www.banden-koning.nl en gebeuren
        volgens de volgende procedure:
        <ul>
          <li>selectie van de goederen in een virtuele winkelwagen;</li>
          <li>
            indien de selectie afgerond is en de Consument besluit om over te
            gaan tot betaling (via klik op de knop ‘<i>doorgaan naar betalen</i>
            ’ of ‘<i>afrekenen</i>’), verschijnt er een winkelwagenoverzicht met
            de totaalprijs van de goederen, de leveringsmethoden (afhaling of
            levering) en de verzendkosten, zodat eventuele foutieve
            aanrekeningen opgespoord en verbeterd kunnen worden vooraleer de
            bestelling definitief wordt geplaatst door de Consument;
          </li>
          <li>invoeren van de adres- en persoonsgegevens van de Consument;</li>
          <li>
            verplichting tot het lezen van de algemene voorwaarden (via een
            pop-up venster dat automatisch verschijnt) en akkoordverklaring met
            de algemene voorwaarden door de Consument;
          </li>
          <li>
            selecteren van de gewenste betaalmethode (directe betaling via
            Paypal of met een betaalkaart) (hetgeen niet kan gebeuren vóór de
            akkoordverklaring met de algemene voorwaarden);
          </li>
          <li>
            betaling via de geselecteerde betaalmethode door de Consument;
          </li>
          <li>
            ontvangst van een email van de Verkoper met een overzicht van de
            bestelling;
          </li>
          <li>
            ontvangst van een bevestigingsmail van de Verkoper, nadat de
            Verkoper de betaling heeft ontvangen.
          </li>
        </ul>
      </p>
      <h3>Artikel 4. Leveringstermijnen</h3>
      <p>
        Tenzij anders vermeld in de Webshop of in de bevestigingsmail, is de
        leveringstermijn 30 dagen. Deze begint te lopen de dag volgend op de dag
        van ontvangst van de bevestigingsmail.
        <br />
        De leveringstermijn wordt in ieder geval verlengd met de duur tijdens
        dewelke uitvoering onmogelijk is wegens overmacht in hoofde van de
        Verkoper (waaronder o.a. te verstaan de jaarlijkse sluiting van de
        leveranciers van de Verkoper).
        <br />
        De Consument aanvaardt uitdrukkelijk dat de leveringstermijn indicatief
        is en bijgevolg geen aanleiding kan geven tot betaling van enige
        schadevergoeding in hoofde van de Verkoper, zonder afbreuk te doen aan
        artikel 10.
      </p>
      <h3>Artikel 5. Levering </h3>
      <p>
        <b>5.1.</b> De Consument kan kiezen om de goederen te laten leveren op
        een door hem gekozen leveringsadres of bij een door de Verkoper erkend
        montagecentrum.
        <br />
        <b>5.2.</b> Levering gebeurt door een partner-transporteur van de
        Verkoper en zal gebeuren op de plaats en het tijdstip zoals schriftelijk
        overeengekomen (na het doorlopen van de bestelprocedure conform artikel
        3) tussen de Consument en de partner-transporteur die daartoe initiatief
        neemt.
        <br />
        <b>5.3.</b> De goederen besteld in eenzelfde winkelwagen zullen in
        principe in één levering worden geleverd, op de beschikbaarheidsdatum
        van het geheel van de bestelde goederen. Ingeval van gesplitste
        verzending, zullen de verzendkosten nooit meer bedragen dan zoals
        aangeduid in het winkelwagenoverzicht overeenkomstig artikel 3.
        <br />
        <b>5.4.</b> De levering van de goederen is een middelenverbintenis in
        hoofde van de Verkoper. De Consument is ertoe gehouden ervoor te zorgen:
        (i) dat de plaats van levering gemakkelijk bereikbaar is en dat er
        voldoende parkeergelegenheid is, (ii) dat de plaats van levering
        geschikt en veilig is en (iii) dat de Verkoper en/of haar aangestelden
        onbeperkt toegang krijgen tot de plaats van levering. De Consument
        garandeert de Verkoper zijn aanwezigheid, of de aanwezigheid van een
        bevoegde vertegenwoordiger, om de bestelde goederen in ontvangst te
        nemen. Voor zover de plaats van levering onbereikbaar, niet
        toegankelijk, ongeschikt en/of onveilig is, of de Consument, of diens
        vertegenwoordiger, niet aanwezig is voor de levering van de goederen, is
        de Verkoper gerechtigd de levering te weigeren. In dat geval zijn alle
        reeds gemaakte en nog te maken kosten ten laste van de Consument en zal
        deze laatste instaan voor afhaling van de goederen.
        <br />
        <b>5.5.</b> Indien de Consument ervoor kiest om de bestelling te laten
        leveren in een montagecentrum, verplicht hij zich ertoe, behoudens
        overmacht, om (i) de bestelling binnen de 30 dagen vanaf de
        leveringsdatum op te halen en (ii) de goederen te laten monteren door
        het betreffende montagecentrum. De concrete afspraken met betrekking tot
        de montage (waaronder de prijs) worden rechtstreeks tussen de Consument
        en het montagecentrum gemaakt. De Verkoper zal niet aansprakelijk kunnen
        worden gehouden voor de ontvangst, opslag, demontage, montage,
        balanceringsdiensten, vertraging in de montage, etc. die onder de
        volledige en exclusieve verantwoordelijkheid van het montagecentrum
        plaatsvinden.
        <br />
        In het geval de Consument de goederen niet tijdig ophaalt, behoudt de
        Verkoper zich het recht om:
        <br />
        (i) de Overeenkomst te ontbinden ten laste van de Consument zonder enige
        schadevergoeding;
        <br />
        (ii) een vergoeding aan te rekenen van 5 EUR per dag vertraging,
        berekend vanaf de leveringsdatum, uit hoofde van stockagevergoeding.
        <br />
        In het geval de Consument weigert om de goederen te laten monteren door
        het montagecentrum waar de goederen zijn geleverd, heeft het
        montagecentrum het recht om een bijkomende vergoeding te vorderen van de
        Consument.
        <br />
        <b>5.6.</b> De levering door de Verkoper is exclusief montage.
      </p>

      <h3>Artikel 6. Eigendomsvoorbehoud</h3>
      <p>
        De overdracht van de eigendom van de goederen geschiedt pas na volledige
        betaling van de prijs en eventuele kosten.
      </p>

      <h3>Artikel 7. Overdracht van risico</h3>
      <p>
        Niettegenstaande artikel 4, wordt het risico op beschadiging of verlies
        van de goederen overgedragen op de Consument vanaf de levering door de
        Verkoper, of de door hem aangestelde partner-transporteur.
      </p>

      <h3>Artikel 8. Herroepingsrecht</h3>
      <p>
        <b>8.1.</b> De Consument heeft, overeenkomstig artikel VI.47 Wetboek
        Economisch Recht, het recht om zonder opgave van redenen de Overeenkomst
        te herroepen binnen een termijn van 14 kalenderdagen te rekenen van de
        dag na de fysieke inbezitname van alle goederen.
        <br />
        <b>8.2.</b> De Consument heeft geen herroepingsrecht voor goederen
        vervaardigd volgens zijn bijzondere specificaties.
        <br />
        <b>8.3.</b> Om zijn herroepingsrecht uit te oefenen moet de Consument
        zijn beslissing per aangetekende brief verzenden aan de Verkoper binnen
        de in artikel 8.1 bepaalde termijn. Hij kan ofwel het ondertekende en
        gesigneerde herroepingsformulier sturen dat beschikbaar is op de
        website, ofwel een brief waarin hij zijn beslissing meedeelt. Indien het
        herroepingsrecht uitgeoefend wordt, zal de Consument de goederen
        terugzenden naar het door de Verkoper opgegeven adres, ongebruikt,
        niet-gemonteerd, onbeschadigd en in hun originele verpakking, binnen 30
        dagen vanaf de verzending van zijn herroepingsbeslissing. De
        verzendkosten zijn ten laste van de Consument. De Verkoper zal een
        partner-transporteur belasten met het transport van de goederen. Hij zal
        alle door de Consument betaalde bedragen, onder aftrek van de
        verzendkosten, terugstorten binnen de 30 dagen na ontvangst van de
        goederen door de Verkoper. Voor deze storting zal dezelfde
        betalingsprocedure worden gevolgd zoals gekozen door de Consument,
        overeenkomstig artikel 3.
      </p>

      <h3>Artikel 9. Klachten – garanties</h3>
      <p>
        <b>9.1.</b> De Consument inspecteert de goederen op zichtbare gebreken
        op het moment van de levering en noteert de desgevallende zichtbare
        gebreken op de leveringsbon. De Consument zal zo spoedig mogelijk zijn
        desgevallende opmerkingen met betrekking tot zichtbare gebreken per
        email aan{" "}
        <a href="mailto:info@banden-koning.nl" className="link">
          info@banden-koning.nl
        </a>{" "}
        meedelen, vergezeld van alle elementen (beschrijving, foto, …) die het
        zichtbaar gebrek bewijzen, bij gebreke waaraan de staat van de goederen
        wordt beschouwd als aanvaard door de Consument.
        <br />
        <b>9.2.</b> Dit artikel doet geen afbreuk aan het herroepingsrecht
        overeenkomstig artikel 8 en de wettelijke garantie van 2 jaar.
      </p>

      <h3>Artikel 10. Vertraging in de levering</h3>
      <p>
        Wanneer de Verkoper de contractuele leveringstermijn met meer dan 2
        weken overschrijdt, moet de Consument de Verkoper bij aangetekend
        schrijven in gebreke stellen, waarbij deze een uiterste termijn van 15
        werkdagen heeft om te leveren. Als de levering niet plaatsvindt binnen
        de voornoemde termijn, zal de Consument de mogelijkheid hebben om de
        Overeenkomst te ontbinden. Daarvoor moet hij binnen de 30 dagen volgend
        op het verstrijken van voornoemde termijn van 15 werkdagen, een
        aangetekend schrijven richten aan de Verkoper. In dat geval wordt de
        prijs die door de Consument betaald werd binnen de 30 dagen
        teruggestort. Zonder afbreuk te doen aan het recht van de Consument om
        een schadevergoeding te vorderen voor de werkelijk geleden schade.
      </p>

      <h3>Artikel 11. Overmacht</h3>
      <p>
        <b>11.1.</b> Elke partij zal overmacht en maatregelen en/of
        verplichtingen die voortvloeien uit nieuwe regelgeving kunnen inroepen
        om alle of een deel van haar verplichtingen (onder meer voortvloeiend
        uit deze algemene voorwaarden) op te zeggen, er de uitvoering van te
        schorsen of te vertragen, zonder opzegging of vergoedingen, zelfs deze
        die voorafgaandelijk tussen de partijen werden overeengekomen.
        <br />
        <b>11.2.</b> Oorlog, oproer, betogingen, blokkades, staking, lock-out,
        machinebreuk, brand, explosie, sociale onrust, epidemieën, zware
        weersomstandigheden, onvoldoende toevoer van energie, brandstoffen,
        grondstoffen of verbruiksgoederen, onderbrekingen van transportmiddelen,
        faillissement of insolventie of elke uitzonderlijke gebeurtenis die de
        vervaardiging, het transport of de verkoop van de goederen verhindert of
        belemmert, worden conventioneel beschouwd als gevallen van overmacht,
        ongeacht of ze zich voordoen bij de Verkoper, zijn onderaannemers of
        leveranciers.
      </p>

      <h3>Artikel 12. Aansprakelijkheid</h3>
      <p>
        De aansprakelijkheid van de Verkoper kan enkel weerhouden worden in
        geval van bedrog, opzet of zware fout in zijn hoofde of in hoofde van
        zijn aangestelden. Zelfs wanneer de Verkoper tot schadevergoeding
        gehouden zou zijn, zal deze altijd beperkt zijn tot de directe schade
        die persoonlijk geleden werd door de Consument. Indirecte schade komt
        niet voor vergoeding in aanmerking.
      </p>

      <h3>Artikel 13. Privacybeleid</h3>
      <p>
        <b>13.1.</b> Overeenkomstig de bepalingen van de Algemene Verordening
        Gegevensbescherming, geeft de Consument expliciet toestemming aan de
        Verkoper om zijn persoonsgegevens te verwerken voor het klantenbeheer,
        de boekhouding en directe marketing op basis van de Overeenkomst die
        tussen hen bestaat. Indien de Consument zich hiertegen wenst te
        verzetten, volstaat het om een brief te richten aan de Verkoper, met
        duidelijke vermelding van zijn naam en volledig adres.
        <br />
        <b>13.2.</b> De Consument kan op elk moment toegang krijgen tot het
        bestand dat zijn persoonsgegevens bevat, om er kennis van te nemen of om
        eventuele correcties aan te brengen.
        <br />
        <b>13.3.</b> Persoonsgegevens worden nooit aan derden meegedeeld.
      </p>

      <h3>Artikel 14. Disclaimer</h3>
      <p>
        <b>14.1.</b> De Consument erkent hierbij dat de Verkoper de
        beschikbaarheid van de websites, via dewelke de goederen worden besteld,
        of de inhoud die erop geplaatst wordt niet garandeert of waarborgt. De
        Verkoper behoudt zich het recht voor om de beschikbaarheid van deze
        internetpagina's (zelfs zonder voorafgaande kennisgeving) of de toegang
        ertoe geheel of gedeeltelijk te beperken. De Verkoper, haar organen,
        werknemers of plaatsvervangers zijn niet aansprakelijk voor enige schade
        die kan ontstaan als gevolg van fouten, vertragingen of onderbrekingen
        in de transmissie, in geval van storingen in de technische apparatuur of
        een dienst, onjuiste inhoud, verlies of verwijdering van gegevens,
        virussen of op enige andere wijze tijdens de bestelling, tenzij deze
        schade opzettelijk of door grove nalatigheid is veroorzaakt door de
        Verkoper.
        <br />
        <b>14.2.</b> Alhoewel de Verkoper veel zorg besteedt aan het uitwerken
        en actualiseren van de Webshop (
        <Link to="/" className="link">
          www.banden-koning.nl
        </Link>
        ), toont deze onvermijdelijk verschillen in vergelijking met de reële
        artikelen, in het bijzonder wat betreft kleuren. Een vermelding in de
        Webshop betekent niet noodzakelijk dat het artikel beschikbaar is. De
        Verkoper wijst alle aansprakelijkheid af ingeval van onjuistheden,
        fouten of weglatingen in de webshop, behalve bij zware fout, opzet of
        bedrog.
        <br />
        <b>14.3.</b> Het is de verantwoordelijkheid van de Consument om ervoor
        te zorgen dat de goederen die hij bestelt, voldoen aan de eisen van de
        voertuigfabrikant. De Verkoper is niet aansprakelijk voor enige schade
        die voortvloeit uit het niet respecteren van de voorschriften en
        aanbevelingen van de fabrikant met betrekking tot de veiligheid en
        betrouwbaarheid van het voertuig, in het bijzonder de maat van de banden
        en velgen, de spanning van de banden, alsmede de montage- en
        opslagomstandigheden van de banden.
      </p>

      <h3>Artikel 15. Splitsbaarheid</h3>
      <p>
        Ingeval een van de bepalingen van deze algemene voorwaarden beschouwd
        zou worden als ongeldig, nietig of niet toepasbaar, tast dit de
        geldigheid, wettigheid of tegenstelbaarheid van de andere bepalingen
        niet aan. In het geval partijen er niet in slagen een nieuw geldig en
        afdwingbaar beding te onderhandelen, kan de bevoegde rechtbank het
        ongeldig of niet afdwingbaar beding matigen tot wat wettelijk
        toelaatbaar is.
      </p>

      <h3>Artikel 16. Toepasselijke wet </h3>
      <p>Het Belgisch recht is toepasselijk op de Overeenkomst.</p>
    </>
  );
}
